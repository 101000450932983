import moment from 'moment'
import { SingleUseLink, SingleUseLinkData } from '@chilipiper/api-type-def/src/bookingLinks'

export const getRouteParams = () => {
  return {
    routeParams: {
      splat: window.location.pathname.slice(1),
    },
  }
}

export const mapDataToContext = ({ info, id }: SingleUseLink): SingleUseLinkData => {
  const context = {
    id,
    type: info.type,
  }
  if (info.type === 'Group') {
    return {
      ...context,
      queue: info.groupTemplateId,
    }
  }
  return {
    ...context,
    templateId: info.templateId,
    assignee: info.assigneeName,
  }
}

export const singleUselinkValidation = {
  isBooked: (data: SingleUseLink) => data.state.type === 'Booked',
  isExpired: (data: SingleUseLink) => {
    const expirationDate = moment(data.createdAtMillis).add(60, 'd').valueOf()
    return expirationDate < new Date().getTime()
  },
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const routesArray = [
  'all',
  'reschedule',
  'calendar',
  'series',
  'dialer',
  'booked',
  'requested',
  'booked-series',
  'book',
  'intermidiate',
  'not-available',
  'max-meetings-reached',
  'domain-max-meetings-reached',
  'critical-error',
  'another-tab',
]
